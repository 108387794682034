@import 'common';
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;500;600;700&display=swap');

// Common styles
html,
body,
#root {
  margin: 0;
  height: 100%;
}

// Gestion des couleurs à laisser ici pour fonctionner aussi dans l'admin
.carstore-website {
  @include generateProps(
                  (
                          'primary600': hexToRGBString(#222938),
                          'primary500': hexToRGBString(#2d374b),
                          'primary400': hexToRGBString(#4d5972),
                          'primary300': hexToRGBString(#77849f),
                          'primary200': hexToRGBString(#b6bdc9),
                          'primary100': hexToRGBString(#e1e5ed),
                          'red': hexToRGBString(#da263d),
                          'grey200': hexToRGBString(#ced3db),
                          'highlight': hexToRGBString(#ee6e2a),
                          'highlight-darken': hexToRGBString(#db5a15),
                          'flash-green': hexToRGBString(#6fd01c),
                          'background': hexToRGBString(#f9fafc),
                  )
  );
}

.easyvo-website {
  @include generateProps(
                  (
                          'primary500': hexToRGBString(#E30714),
                          'primary400': hexToRGBString(#B80A1D),
                          'primary300': hexToRGBString(#E30714),
                          'primary200': hexToRGBString(#E30714),
                          'primary100': hexToRGBString(#E30714),
                          'neutral100': hexToRGBString(#000000),
                          'neutral10': hexToRGBString(#EAEAEA),
                          'neutral40': hexToRGBString(#A9A9A9),
                          'neutral80': hexToRGBString(#3B3B3B),
                          'highlight': hexToRGBString(#E30714),
                          'secondary': hexToRGBString(#fbba01),
                          'background': hexToRGBString(#ffffff),
                  )
  );
}

html {
  scroll-behavior: smooth;
}

* {
  outline: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.smartfire-block {
  > div.children {
    display: none;
  }
}

/* Main column width */
.wp-block {
  max-width: 70vw;
}

/* Width of "wide" blocks */
.wp-block[data-align='wide'] {
  max-width: unset;
}

/* Width of "full-wide" blocks */
.wp-block[data-align='full'] {
  max-width: unset;
}

.edit-post-sidebar {
  .editor-url-input {
    input {
      max-width: calc(100% - 50px);
    }
  }
}

.playground {
  height: unset !important;

  .editor {
    .editor-styles-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.searchMobile .fb_dialog .fb_dialog_content {
  iframe {
    bottom: 88px !important;
  }
}
