@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap');

// Breakpoints
$tabletBreakpoint: 1100px;
$mobileBreakpoint: 768px;
$notebookBreakpoint: 1240px;
$desktopBreakpoint: 1500px;
$navbarBreakpoint: 1800px;

$contentFont: 'Red Hat Text', sans-serif;
$titleFont: 'Red Hat Text', sans-serif;
$titleFontEasyVO: 'Raleway', sans-serif;

@function getColor($name, $opacity: 1) {
    @return rgba(var(--sf-#{$name}), $opacity);
}

@function getPxFromPt($value) {
    @return ($value * 1.328) + px;
}

// Functions
@function getUnit($value) {
    @return str-slice($value * 0 + '', 2, -1);
}

@function toRem($value) {
    @if getUnit($value) == pt {
        @return $value;
    }
    @return math.div($value, 16) + rem;
}

@function getPxFromPt($value) {
    @return ($value * 1.328) + px;
}

//We have to convert color in RGB for future use with var and rgba
@function hexToRGBString($hexColor) {
    @return (#{red($hexColor)}, #{green($hexColor)}, #{blue($hexColor)});
}

@mixin generateProps($colors) {
    @each $key, $color in $colors {
        --sf-#{$key}: #{$color};
    }
}

@mixin contentWidth(
    $contentWidth: var(--sf-content-width, 100%),
    $minMargin: 50px,
    $minMarginTablet: 25px,
    $minMarginMobile: 20px
) {
    max-width: $contentWidth;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: calc($contentWidth + 2 * $minMargin)) {
        margin-left: $minMargin;
        margin-right: $minMargin;
    }
    @media (max-width: $tabletBreakpoint) {
        max-width: 100%;
        margin-left: $minMarginTablet;
        margin-right: $minMarginTablet;
    }
    @media (max-width: $mobileBreakpoint) {
        margin-left: $minMarginMobile;
        margin-right: $minMarginMobile;
    }
}

@mixin mobile {
    @media screen and (max-width: $mobileBreakpoint) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tabletBreakpoint) {
        @content;
    }
}

@mixin notebook {
    @media screen and (max-width: $notebookBreakpoint) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $tabletBreakpoint + 1px) {
        @content;
    }
}

@mixin easyvo {
    :global(.easyvo-website) {
        @content;
    }
}

@mixin carstore {
    :global(.carstore-website) {
        @content;
    }
}

@mixin headings {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @content;
    }
}

@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

@function getMarginDirection($direction, $pseudoElement) {
    @if ($direction == 'horizontal') {
        @if ($pseudoElement == 'after') {
            @return 'margin-top';
        } @else {
            @return 'margin-bottom';
        }
    } @else {
        @if ($pseudoElement == 'after') {
            @return 'margin-right';
        } @else {
            @return 'margin-left';
        }
    }
}

@mixin dashedBorder(
    $color: $black,
    $direction: 'horizontal',
    $padding: 0,
    $paddingTablet: $padding,
    $paddingMobile: $padding,
    $pseudoElement: 'after'
) {
    position: relative;

    $marginDirection: getMarginDirection($direction, $pseudoElement);

    &:#{$pseudoElement} {
        content: '';
        display: block;

        @if ($direction == 'horizontal') {
            width: 100%;
            height: 1px;
            background-image: linear-gradient(to right, $color 5px, rgba(255, 255, 255, 0) 5px);
            background-repeat: repeat-x;
            background-position: bottom;
            background-size: 10px 1px;
            #{$marginDirection}: $padding;

            @include tablet {
                #{$marginDirection}: $paddingTablet;
            }

            @include mobile {
                #{$marginDirection}: $paddingMobile;
            }
        } @else {
            height: 100%;
            width: 1px;
            background-image: linear-gradient(to bottom, $color 5px, rgba(255, 255, 255, 0) 5px);
            background-repeat: repeat-y;
            background-position: right;
            background-size: 1px 10px;
            #{$marginDirection}: $padding;
            position: absolute;
            top: 0;
            right: 0;

            @include tablet {
                #{$marginDirection}: $paddingTablet;
            }

            @include mobile {
                #{$marginDirection}: $paddingMobile;
            }
        }
    }
}

@mixin easyVOBackgroundGradient() {
    background-image: linear-gradient(
        216.62deg,
        #fab900 2.52%,
        #f48d04 13.15%,
        #ed5d09 25.72%,
        #e8370d 38.28%,
        #e41c10 49.88%,
        #e20b12 60.52%,
        #e20613 68.25%,
        #e20613 99.18%
    );
}

// Exports
:export {
    tabletBreakpoint: $tabletBreakpoint;
    mobileBreakpoint: $mobileBreakpoint;
    notebookBreakpoint: $notebookBreakpoint;
    desktopBreakpoint: $desktopBreakpoint;
}

// Colors
$black: #2f2f2f;
$white: #ffffff;
